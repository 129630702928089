import React from "react"
import GetHelpIntro from "../components/pages/get-help/get-help-intro"
import Layout from "../components/layout"
import Testimonials from "../components/pages/about-us/testimonials"
import MediaMentions from "../components/utils/media"
import { TradingSteps } from "../components/utils/trading"

const GetHelp = () => {
  return (
    <Layout title="Get Help" description='Need help? Get access to any of our support tools to assist you to trade the financial markets with ease.'>
      <GetHelpIntro />
      <Testimonials/>
      <MediaMentions/>
      <TradingSteps long/>
    </Layout>
  )
}

export default GetHelp