import React from "react"
import Flex from "../../utils/flex"
import ButtonGroup from "../../utils/button/group"
import ctl from "@netlify/classnames-template-literals"
import Container from "../../utils/container"
import financialComissionBadge from "assets/images/pages/home/financial-commission.webp"
import { DesktopBreakpoint, MobileBreakpoint } from "../../breakpoints"
import GetHelpAnimation from "../../animations/get-help-animation"

const buttons = [
  {
    label: "Talk to Harry",
    url: "https://t.me/Egmarketsbot",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
  {
    label: "Try Demo Trading",
    url: "https://mt4.egmarkets.trade/",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  }
]

const GetHelpIntro = () => {
  return (
    <Container
      size='header'
      style={{
        'min-height': 'min(calc(100vh - 104px), 750px)',
      }}
      className='lg:!pl-4 lg:pr-[72px] mb-[80px]'
    >
      <Flex
        isAtTopOfPage
        alignTop
        firstItem={
          <>
            <h1 className="text-[28px] md:text-[40px] leading-[45px] md:leading-[1.25] mb-[30px] font-extrabold">
              Need help or got <MobileBreakpoint/> questions? <DesktopBreakpoint/>
              Talk to Harry, <MobileBreakpoint/> he’ll help out.
            </h1>
            <p className="mb-8">
              Start a conversation with me on <a href='http://t.me/Egmarketsbot' rel='noreferrer' target='_blank' className="no-underline text-button-blue font-bold">Telegram.</a> <br className='!block'/>
              I’ll help find solutions & answer questions.
            </p>
            <ButtonGroup
              buttons={buttons}
              fullWidthOnMobile
              className='md-max:gap-y-[20px]'
            />
            <a href='https://financialcommission.org/eagle-global-markets/' target='_blank' className={`${financialCommissionStyle} hidden`}>
              <img src={financialComissionBadge} alt="" className="w-full" />
            </a>
          </>
        }
        className="md:!pt-[60px] md:!w-full"
        firstItemClassName='md:pt-[50px]'
        secondItem={
          <>
            <div className={`${imageStyle} lottie-animation`}>
              <GetHelpAnimation />
            </div>
            <a href='https://financialcommission.org/eagle-global-markets/' target='_blank' className={`${financialCommissionStyle} hidden `}>
              <img src={financialComissionBadge} alt="" className="w-full" />
            </a>
          </>
        }
        secondItemClassName='lg-max:mt-[56px]'
      />
    </Container>
  )
}

const imageStyle = ctl(`mx-auto max-w-[472px]`)

const financialCommissionStyle = ctl(
  ` md:w-[343px] lg:mx-0 mx-auto md-max:max-w-[307px] md:mt-[50px] mt-[70px]`
)

export default GetHelpIntro